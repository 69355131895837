import { ProcessRapport } from "../../models/rapportModel";
import { Container, Col, Row, Alert} from "react-bootstrap";
import { DkkFormatter } from "../../services/valutaFormatering";
import { formatNumberOneDecimal } from "../../services/tidsFormatering";
import Barometer from "../barometer";
import calculateROI from "../../services/ROIService";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState } from "react";
import QuestionIconWithTooltip from "../questionCirkelTooltip";
import ROIChartV2 from "../roi-chartV2";
//import AdsComponent from "../adsense";

const RapportMedarbFrekLoen: React.FC<{rapport: ProcessRapport}> = ({rapport}) => {
    // Styling for the Bootstrap table
    const bootstrapStylingCol1 = "bg-other-color2 border-end border-start border-bottom border-dark";
    const bootstrapStylingCol2 = "bg-white border-end border-bottom border-dark";
    const breakingPointCol1 = 4;

    const ROI3Mdr = calculateROI(3, rapport.estimeretReduction?.estimeretReductionPengeMåned ?? 0, rapport.tilbudPåLøsning?.tilbudPris ?? 0, rapport.tilbudPåLøsning?.løbendeUdgifterFremadrettetPrÅr ? (rapport.tilbudPåLøsning.løbendeUdgifterFremadrettetPrÅr / 12) : 0);
    const ROI6Mdr = calculateROI(6, rapport.estimeretReduction?.estimeretReductionPengeMåned ?? 0, rapport.tilbudPåLøsning?.tilbudPris ?? 0, rapport.tilbudPåLøsning?.løbendeUdgifterFremadrettetPrÅr ? (rapport.tilbudPåLøsning.løbendeUdgifterFremadrettetPrÅr / 12) : 0);
    const ROI12Mdr = calculateROI(12, rapport.estimeretReduction?.estimeretReductionPengeMåned ?? 0, rapport.tilbudPåLøsning?.tilbudPris ?? 0, rapport.tilbudPåLøsning?.løbendeUdgifterFremadrettetPrÅr ? (rapport.tilbudPåLøsning.løbendeUdgifterFremadrettetPrÅr / 12) : 0);
    const ROI2Year = calculateROI(24, rapport.estimeretReduction?.estimeretReductionPengeMåned ?? 0, rapport.tilbudPåLøsning?.tilbudPris ?? 0, rapport.tilbudPåLøsning?.løbendeUdgifterFremadrettetPrÅr ? (rapport.tilbudPåLøsning.løbendeUdgifterFremadrettetPrÅr / 12) : 0);
    const ROI3Year = calculateROI(36, rapport.estimeretReduction?.estimeretReductionPengeMåned ?? 0, rapport.tilbudPåLøsning?.tilbudPris ?? 0, rapport.tilbudPåLøsning?.løbendeUdgifterFremadrettetPrÅr ? (rapport.tilbudPåLøsning.løbendeUdgifterFremadrettetPrÅr / 12) : 0);
    const ROI4Year = calculateROI(48, rapport.estimeretReduction?.estimeretReductionPengeMåned ?? 0, rapport.tilbudPåLøsning?.tilbudPris ?? 0, rapport.tilbudPåLøsning?.løbendeUdgifterFremadrettetPrÅr ? (rapport.tilbudPåLøsning.løbendeUdgifterFremadrettetPrÅr / 12) : 0);
    const ROI5Year = calculateROI(60, rapport.estimeretReduction?.estimeretReductionPengeMåned ?? 0, rapport.tilbudPåLøsning?.tilbudPris ?? 0, rapport.tilbudPåLøsning?.løbendeUdgifterFremadrettetPrÅr ? (rapport.tilbudPåLøsning.løbendeUdgifterFremadrettetPrÅr / 12) : 0);
    
    const [useIronRule, setUseIronRule] = useState<boolean>(false);
    
    return (
        <Container className="p-4 pt-2">
            {/* <AdsComponent/> */}
            <h3>Basis oplysninger:</h3>
            <Container className="pb-5" fluid="md">
                {/* Basis Information */}
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1+" border-top rounded-tl"}><b>Dato og tid:</b></Col>
                    <Col className={bootstrapStylingCol2+" border-top rounded-tr"}>
                        {rapport.basisInfo.generatedDate.toLocaleDateString('da-DK', {
                            hour: '2-digit',
                            minute: '2-digit',
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        })}
                    </Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}><b>Process Navn:</b> </Col>
                    <Col className={bootstrapStylingCol2}>{rapport.basisInfo.processNavn}</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}><b>Process Beskrivelse:</b></Col>
                    <Col className={bootstrapStylingCol2}>{rapport.basisInfo.processBeskrivelse}</Col>
                </Row>

                {/* Omfang - Medarbejdere */}
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}><b>Medarbejder-oplysninger:</b></Col>
                    <Col className={bootstrapStylingCol2}></Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1} style={{ paddingLeft: '20px' }}>Antal medarbejdere:</Col>
                    <Col className={bootstrapStylingCol2}>{rapport.omfang?.medarbejdere.antalMedarbejdere}</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1} style={{ paddingLeft: '20px' }}>Gennemsnitlig timeløn (brutto):</Col>
                    <Col className={bootstrapStylingCol2}>{rapport.omfang?.medarbejdere.gennemsnitligTimeløn}</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1} style={{ paddingLeft: '20px' }}>Ferieuger pr. år:</Col>
                    <Col className={bootstrapStylingCol2}>{rapport.omfang?.medarbejdere.ferieUgerPrÅr}</Col>
                </Row>

                {/* Omfang - Process */}
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}><b>Frekvens og Nuværende Anvendte Tid:</b></Col>
                    <Col className={bootstrapStylingCol2}></Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1} style={{ paddingLeft: '20px' }}>Frekvens:</Col>
                    <Col className={bootstrapStylingCol2}>{rapport.omfang?.process.frekvens}</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1} style={{ paddingLeft: '20px' }}>Tid brugt pr. år:</Col>
                    <Col className={bootstrapStylingCol2}>{formatNumberOneDecimal(rapport.økonomi?.tidBrugtPrÅr)} løntimer</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1} style={{ paddingLeft: '20px' }}>Tid brugt pr. måned:</Col>
                    <Col className={bootstrapStylingCol2}>{formatNumberOneDecimal(rapport.økonomi?.tidBrugtPrMåned)} løntimer</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1} style={{ paddingLeft: '20px' }}>Tid brugt pr. uge:</Col>
                    <Col className={bootstrapStylingCol2}>{formatNumberOneDecimal(rapport.økonomi?.tidBrugtPrUge)} løntimer</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1} style={{ paddingLeft: '20px' }}>Tid brugt pr. dag:</Col>
                    <Col className={bootstrapStylingCol2}>{formatNumberOneDecimal(rapport.økonomi?.tidBrugtPrDag)} løntimer</Col>
                </Row>

                {/* Økonomi */}
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}><b>Nuværende Omkostninger:</b></Col>
                    <Col className={bootstrapStylingCol2}></Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1} style={{ paddingLeft: '20px' }}>Omkostinger pr. år:</Col>
                    <Col className={bootstrapStylingCol2}>{DkkFormatter(rapport.økonomi?.pengeBrugtPrÅr)} kr.-</Col>
                </Row>

                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1} style={{ paddingLeft: '20px' }}>Omkostinger pr. måned:</Col>
                    <Col className={bootstrapStylingCol2}>{DkkFormatter(rapport.økonomi?.pengeBrugtPrMåned)} kr.-</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1} style={{ paddingLeft: '20px' }}>Omkostinger pr. uge:</Col>
                    <Col className={bootstrapStylingCol2}>{DkkFormatter(rapport.økonomi?.pengeBrugtPrUge)} kr.-</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1+" rounded-bl shadow"} style={{ paddingLeft: '20px' }}>Omkostinger pr. dag:</Col>
                    <Col className={bootstrapStylingCol2+" rounded-br shadow"}>{DkkFormatter(rapport.økonomi?.pengeBrugtPrDag)} kr.-</Col>
                </Row>
            </Container>
            <Container className="p-5 bg-white rounded shadow" fluid="md">
                    {(rapport.tilbudPåLøsning?.tilbud ? 
                    <>
                    <Row>
                        <Col xs={4}>
                                <Barometer label="Risiko" value={rapport.ROI?.monthsUntilEven ? rapport.ROI?.monthsUntilEven * (100/24) : 0}/>
                        </Col>
                        <Col xs={8}>
                            <h3>Konklusion</h3>
                            
                                {rapport.ROI?.monthsUntilEven !== undefined && (
                                    rapport.ROI.monthsUntilEven <= 3 ? <p>Den nuværende løsning koster pt. {DkkFormatter(rapport.økonomi?.pengeBrugtPrÅr)} Kr.- om året.<br/>
                                    Med en investering i en løsning der koster {DkkFormatter(rapport.tilbudPåLøsning?.tilbudPris)} Kr.- og som reducerer tid brugt af personale med {rapport.estimeretReduction?.estimeretReductionProcent}%, og en forventet løbende udgift på {DkkFormatter(rapport.tilbudPåLøsning.løbendeUdgifterFremadrettetPrÅr)}, vil investeringen give tidsmæssigt, eller økonomisk afkast indenfor {formatNumberOneDecimal(rapport.ROI.monthsUntilEven)} måneder.<br/>
                                    <br/>
                                    Som tommelfingerregel har et automatiseringsprojekt en meget lav risiko hvis det tager mindre end 3 måneder at tjene investeringen hjem igen. Det vil i de fleste sammenhænge blive anset for en lavthængende frugt.</p> :
                                    rapport.ROI.monthsUntilEven <= 6 ? <p> Den nuværende løsning koster pt. {DkkFormatter(rapport.økonomi?.pengeBrugtPrÅr)} Kr.- om året.<br/>
                                    Med en investering i en løsning der koster {DkkFormatter(rapport.tilbudPåLøsning?.tilbudPris)} Kr.- og som reducerer tid brugt af personale med {rapport.estimeretReduction?.estimeretReductionProcent}%, og en forventet løbende udgift på {DkkFormatter(rapport.tilbudPåLøsning.løbendeUdgifterFremadrettetPrÅr)}, vil investeringen give tidsmæssigt, eller økonomisk afkast indenfor {formatNumberOneDecimal(rapport.ROI.monthsUntilEven)} måneder.<br/>
                                    <br/>
                                    Som tommelfingerregel har et automatiseringsprojekt en lav risiko hvis det tager mellem 3 og 6 måneder at tjene investeringen hjem igen.</p> :
                                    rapport.ROI.monthsUntilEven <= 12 ? <p> Den nuværende løsning koster pt. {DkkFormatter(rapport.økonomi?.pengeBrugtPrÅr)} Kr.- om året.<br/>
                                    Med en investering i en løsning der koster {DkkFormatter(rapport.tilbudPåLøsning?.tilbudPris)} Kr.- og som reducerer tid brugt af personale med {rapport.estimeretReduction?.estimeretReductionProcent}%, og en forventet løbende udgift på {DkkFormatter(rapport.tilbudPåLøsning.løbendeUdgifterFremadrettetPrÅr)}, vil investeringen give tidsmæssigt, eller økonomisk afkast indenfor {formatNumberOneDecimal(rapport.ROI.monthsUntilEven)} måneder.<br/>
                                    <br/>
                                    Som tommelfingerregel har et automatiseringsprojekt en medium risiko hvis det tager mellem 6 og 12 måneder at tjene investeringen hjem igen. Det bør her overvejes om løsningen forventes at blive anvendt i længere tid, og tage i betragtning om ændringen læner sig op af organistationens strategiske målsætninger på længere sigt.</p> :
                                    rapport.ROI.monthsUntilEven <= 24 ? <p> Den nuværende løsning koster pt. {DkkFormatter(rapport.økonomi?.pengeBrugtPrÅr)} Kr.- om året.<br/>
                                    Med en investering i en løsning der koster {DkkFormatter(rapport.tilbudPåLøsning?.tilbudPris)} Kr.- og som reducerer tid brugt af personale med {rapport.estimeretReduction?.estimeretReductionProcent}%, og en forventet løbende udgift på {DkkFormatter(rapport.tilbudPåLøsning.løbendeUdgifterFremadrettetPrÅr)}, vil investeringen give tidsm��ssigt, eller økonomisk afkast indenfor {formatNumberOneDecimal(rapport.ROI.monthsUntilEven)} måneder.<br/>
                                    <br/>
                                    Som tommelfingerregel har et automatiseringsprojekt en over middel risiko hvis det tager mellem 1 og 2 år at tjene investeringen hjem igen. Overvej om en anden løsning kan være bedre eller om fordelen opvejer risikoen.<br/>
                                    <br/>
                                    Det bør her overvejes om løsningen forventes at blive anvendt i længere tid, og tage i betragtning om ændringen læner sig op af organistationens strategiske målsætninger på længere sigt.<br/>
                                    <br/>
                                    Desuden bør organistationen overveje om en risiko-analyse skal udfærdiges, med en oversigt over de hændelser som er til størst fare for projektets succes - husk også at lægge planer for håndtering af kritiske hændelser.</p> :
                                    "Besked for over 24 måneder"
                                )}
                            
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <h3 className="mb-0">ROI udvikling over tid:</h3>
                            {window.innerWidth >= 850 && (
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Periode</th>
                                        <th>Efter 3 mdr.</th>
                                        <th>Efter 6 mdr.</th>
                                        <th>Efter 1 år.</th>
                                        <th>Efter 2 år</th>
                                        <th>Efter 3 år</th>
                                        <th>Efter 4 år</th>
                                        <th>Efter 5 år</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>ROI</td>
                                        <td>{ROI3Mdr}%</td>
                                        <td>{ROI6Mdr}%</td>
                                        <td>{ROI12Mdr}%</td>
                                        <td>{ROI2Year}%</td>
                                        <td>{ROI3Year}%</td>
                                        <td>{ROI4Year}%</td>
                                        <td>{ROI5Year}%</td>
                                    </tr>
                                </tbody>
                            </table>
                            )}
                            {window.innerWidth <= 849 && (
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Periode</th>
                                        <th>ROI</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Efter 3 mdr.</td>
                                        <td>{ROI3Mdr}%</td>
                                    </tr>
                                    <tr>
                                        <td>Efter 6 mdr.</td>
                                        <td>{ROI6Mdr}%</td>
                                    </tr>
                                    <tr>
                                        <td>Efter 1 år</td>
                                        <td>{ROI12Mdr}%</td>
                                    </tr>
                                    <tr>
                                        <td>Efter 2 år</td>
                                        <td>{ROI2Year}%</td>
                                    </tr>
                                    <tr>
                                        <td>Efter 3 år</td>
                                        <td>{ROI3Year}%</td>
                                    </tr>
                                    <tr>
                                        <td>Efter 4 år</td>
                                        <td>{ROI4Year}%</td>
                                    </tr>
                                    <tr>
                                        <td>Efter 5 år</td>
                                        <td>{ROI5Year}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                        </Col>
                    </Row>
                    <Row>
                        <div className="mb-2 mt-4 mx-2">
                            <QuestionIconWithTooltip text="Tryk på grafen for at se den i fuld skærm"/>
                        </div>
                        <Col 
                            style={{ overflow: 'hidden', transition: 'transform 0.3s ease-in-out'}}
                            onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                            onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'} 
                        >
                            <div style={{ width: '100%', height: 'auto' }} >
                                <ROIChartV2 ROI3Mdr={ROI3Mdr} ROI6Mdr={ROI6Mdr} ROI12Mdr={ROI12Mdr} ROI2Year={ROI2Year} ROI3Year={ROI3Year} ROI4Year={ROI4Year} ROI5Year={ROI5Year} />
                            </div>
                        </Col>
                    </Row>
                    </>
                    :
                    <>
                        <Row>
                            <h3>Konklusion</h3>
                            <p>
                                Den nuværende løsning koster pt. {DkkFormatter(rapport.økonomi?.pengeBrugtPrÅr)} kr. om året, hvilket svarer til {formatNumberOneDecimal(rapport.økonomi?.tidBrugtPrÅr)} løntimer.<br/>
                                Hvis tilbudet lever op til at reducerer mængenden af medarbejder-timer med {rapport.estimeretReduction.estimeretReductionProcent}% - vil det udmunde i en årlig besparelse på {formatNumberOneDecimal(rapport.estimeretReduction.estimeretReductionProcessTidÅr)} løntimer.<br/>
                                Det vil svare til en besparelse på {DkkFormatter(rapport.estimeretReduction.estimeretReductionPengeÅr)} kr. om året.<br/>
                                <br/>
                                Under denne tekst er der vist eksempler på tilbud.<br/>
                                Eksemplerne kan bruges i forbindelse med indhentning af tilbud, og kan give en overordnet ide om risiko-niveauet. <br/>
                                Eksemplerne er sat op efter at den samlede investering er tjent hjem inden for de første 3, 6, 12 og 24 måneder. <br/>
                                <br/>
                                De fleste automatiseringsprojekter vil være rentable hvis de tjener investeringen hjem inden for 6-12 måneder. <br/>
                                Hvis projektet er tjent hjem inden for 3 måneder, vil det ofte blive anset for en lavthængende frugt. <br/>
                                <br/>
                                Vær opmærksom på at der kan være andre faktorer som spiller ind, som f.eks. strategiske målsætninger, eller andre forhold som kan have indflydelse på projektets kan betale sig selvom det måske tager længere tid at tjene investeringen hjem igen. <br/>
                                Der kan også være andre hensyn andet end økonomiske, målsætninger som kan have betydning for om projektet giver mening at gennefører. <br/>
                                Det kan fx. være hvis målet er at reducerer fejl, forkorte den samlede tid for en proces, eller reducerer CO2 aftryk. <br/>
                                <br/>
                                Være opmærksom på, at når der er indhentet tilbud, skal der både tages højde for engangsudgifter i forbindelse med etableringen af automatiseringen, men også tages højde for løbende udgifter efterfølgende.
                            </p>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <h4 className="text-center">Tilbud på op til <br/>{DkkFormatter(rapport.estimeretReduction?.estimeretReductionPengeMåned*3)} kr.</h4>
                                <Barometer label="Risiko" value={0}/>
                                <Alert className="text-center" variant="success">Meget Lav Risiko</Alert>
                                <p>Et tilbud hvor de samlede udgifter (både engangsbeløb og løbende udgifter) på {DkkFormatter(rapport.estimeretReduction.estimeretReductionPengeMåned*3)} kr. vil give et afkast på investeringen efter 3 måneder.<br/>
                                Husk at tage højde for løbende udgifter når du indhentet tilbud.
                                </p>
                            </Col>
                            <Col xs={3}>
                                <h4 className="text-center">Tilbud på op til <br/>{DkkFormatter(rapport.estimeretReduction?.estimeretReductionPengeMåned*6)} kr.</h4>
                                <Barometer label="Risiko" value={33}/>
                                <Alert className="text-center" variant="success">Lav Risiko</Alert>
                                <p>Et tilbud hvor de samlede udgifter (både engangsbeløb og løbende udgifter) på {DkkFormatter(rapport.estimeretReduction.estimeretReductionPengeMåned*6)} kr. vil give et afkast på investeringen efter 6 måneder.<br/>
                                Husk at tage højde for løbende udgifter når du indhentet tilbud.
                                </p>
                            </Col>
                            <Col xs={3}>
                                <h4 className="text-center">Tilbud på op til <br/>{DkkFormatter(rapport.estimeretReduction?.estimeretReductionPengeMåned*12)} kr.</h4>
                                <Barometer label="Risiko" value={66}/>
                                <Alert className="text-center" variant="warning">Medium Risiko</Alert>
                                <p>Et tilbud hvor de samlede udgifter (både engangsbeløb og løbende udgifter) på {DkkFormatter(rapport.estimeretReduction.estimeretReductionPengeMåned*12)} kr. vil give et afkast på investeringen efter 12 måneder.<br/>
                                Husk at tage højde for løbende udgifter når du indhentet tilbud.
                                </p>
                            </Col>
                            <Col xs={3}>
                                <h4 className="text-center">Tilbud på op til <br/>{DkkFormatter(rapport.estimeretReduction?.estimeretReductionPengeMåned*24)} kr.</h4>
                                <Barometer label="Risiko" value={100}/>
                                <Alert className="text-center" variant="danger">Høj Risiko</Alert>
                                <p>Et tilbud hvor de samlede udgifter (både engangsbeløb og løbende udgifter) på {DkkFormatter(rapport.estimeretReduction.estimeretReductionPengeMåned*24)} kr. vil give et afkast på investeringen efter 24 måneder.<br/>
                                Husk at tage højde for løbende udgifter når du indhentet tilbud.
                                </p>
                            </Col>
                        </Row>
                    </>)}
                </Container>
            {/* Estimeret Reduktion */}
            <Container className="pt-3 pb-3" fluid="md">
                <h3>Estimeret reduction af tid</h3>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>Estimeret reduktion i procent:</Col>
                    <Col className={bootstrapStylingCol2}>{rapport.estimeretReduction?.estimeretReductionProcent}%</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>Estimeret reduktion i proces tid pr. år:</Col>
                    <Col className={bootstrapStylingCol2}>-{formatNumberOneDecimal(rapport.estimeretReduction?.estimeretReductionProcessTidÅr)} timer pr. år</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>Estimeret reduktion i proces tid pr. måned:</Col>
                    <Col className={bootstrapStylingCol2}>-{formatNumberOneDecimal(rapport.estimeretReduction?.estimeretReductionProcessTidMåned)} timer pr. måned</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>Estimeret reduktion i proces tid pr. uge:</Col>
                    <Col className={bootstrapStylingCol2}>-{formatNumberOneDecimal(rapport.estimeretReduction?.estimeretReductionProcessTidUge)} timer pr. uge</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>Estimeret reduktion i medarbejder tid pr. år:</Col>
                    <Col className={bootstrapStylingCol2}>-{formatNumberOneDecimal(rapport.estimeretReduction?.estimeretReductionMedarbejderTidÅr)} timer pr. år</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>Estimeret reduktion i medarbejder tid pr. måned:</Col>
                    <Col className={bootstrapStylingCol2}>-{formatNumberOneDecimal(rapport.estimeretReduction?.estimeretReductionMedarbejderTidMåned)} timer pr. måned</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>Estimeret reduktion i medarbejder tid pr. uge:</Col>
                    <Col className={bootstrapStylingCol2}>-{formatNumberOneDecimal(rapport.estimeretReduction?.estimeretReductionMedarbejderTidUge)} timer pr. uge</Col>
                </Row>
                </Container>
                {/* Estimeret reduktion af udgifter */}
                <Container className="pt-3 pb-3" fluid="md">
                <h3>Estimeret reduction af udgifter</h3>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>Estimeret reduktion i procent:</Col>
                    <Col className={bootstrapStylingCol2}>{rapport.estimeretReduction?.estimeretReductionProcent}%</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>Estimeret reduktion i udgifter pr. år:</Col>
                    <Col className={bootstrapStylingCol2}>-{DkkFormatter(rapport.estimeretReduction?.estimeretReductionPengeÅr)} kr.-</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>Estimeret reduktion i udgifter pr. måned:</Col>
                    <Col className={bootstrapStylingCol2}>-{DkkFormatter(rapport.estimeretReduction?.estimeretReductionPengeMåned)} kr.-</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>Estimeret reduktion i udgifter pr. uge:</Col>
                    <Col className={bootstrapStylingCol2}>-{DkkFormatter(rapport.estimeretReduction?.estimeretReductionPengeUge)} kr.-</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>Estimeret reduktion i udgifter pr. dag:</Col>
                    <Col className={bootstrapStylingCol2}>-{DkkFormatter(rapport.estimeretReduction?.estimeretReductionPengeDag)} kr.-</Col>
                </Row>
            </Container>
            {/* Tilbud på løsning */}
            {(rapport.tilbudPåLøsning?.tilbud ? 
            <Container className="pt-3 pb-3" fluid="md">
                <h3>Tilbud på løsning</h3>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>Indhentet tilbud:</Col>
                    <Col className={bootstrapStylingCol2}>{(rapport.tilbudPåLøsning?.tilbud ? "Ja" : "Nej")}</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>Tilbud pris:</Col>
                    <Col className={bootstrapStylingCol2}>{DkkFormatter(rapport.tilbudPåLøsning?.tilbudPris)} kr.-</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>Løbende udgifter fremadrettet (pr. måned):</Col>
                    <Col className={bootstrapStylingCol2}>{DkkFormatter((rapport.tilbudPåLøsning?.løbendeUdgifterFremadrettetPrÅr ? rapport.tilbudPåLøsning.løbendeUdgifterFremadrettetPrÅr : 0)/12)} kr.-</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>Løbende udgifter fremadrettet (pr. år):</Col>
                    <Col className={bootstrapStylingCol2}>{DkkFormatter(rapport.tilbudPåLøsning?.løbendeUdgifterFremadrettetPrÅr)} kr.-</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>ROI 3mdr:</Col>
                    <Col className={bootstrapStylingCol2}>{formatNumberOneDecimal(rapport.ROI?.ROI3mdr)}%</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>ROI 6mdr:</Col>
                    <Col className={bootstrapStylingCol2}>{formatNumberOneDecimal(rapport.ROI?.ROI6mdr)}%</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>ROI 12mdr:</Col>
                    <Col className={bootstrapStylingCol2}>{formatNumberOneDecimal(rapport.ROI?.ROI12mdr)}%</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>ROI 2 år:</Col>
                    <Col className={bootstrapStylingCol2}>{formatNumberOneDecimal(rapport.ROI?.ROI2year)}%</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>ROI 5 år:</Col>
                    <Col className={bootstrapStylingCol2}>{formatNumberOneDecimal(rapport.ROI?.ROI5year)}%</Col>
                </Row>
                <Row>
                    <Col lg={breakingPointCol1} className={bootstrapStylingCol1}>Måneder til udligning:</Col>
                    <Col className={bootstrapStylingCol2}>{formatNumberOneDecimal(rapport.ROI?.monthsUntilEven)}</Col>
                </Row>
            </Container>
            : null)}
            {/* <div className="d-flex align-items-center">
                <Form.Check 
                    type="checkbox" 
                    label="Se hvordan rapport ser ud hvis vi er 20% pessimistisk" 
                    onChange={(e) => setUseIronRule(e.target.checked)} 
                />
                <QuestionIconWithTooltip text='Efter at have lavet vores bedste estimater forud for et projekt, ses det oftest at der ikke er taget højde for ubekendte faktorer. Derfor kan man med fordel se hvordan ens projekt ser ud hvis udgifterne forøges med 20% og fortjeneste reduceres med 20% procent.' placement="right" className="m-1"/>
            </div> */}
        </Container>
    );
};
export default RapportMedarbFrekLoen;
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Modal, Button } from 'react-bootstrap';

const GDPRModal: React.FC = () => {
  const [preferences, setPreferences] = useState({
    analytics: true, // Default til valgt
    advertising: true, // Default til valgt
  });
  const [isPreferencesSet, setIsPreferencesSet] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Læs gemte præferencer, hvis de findes
    const savedPreferences = Cookies.get('gdprPreferences');
    if (savedPreferences) {
      setPreferences(JSON.parse(savedPreferences));
      setIsPreferencesSet(true); // Brugeren har allerede taget stilling
    } else {
      setShowModal(true); // Vis modal hvis præferencer ikke er sat
    }
  }, []);

  const handlePreferenceChange = (key: string, value: boolean) => {
    setPreferences((prev) => ({ ...prev, [key]: value }));
  };

  const savePreferences = () => {
    // Gem præferencer som en cookie
    Cookies.set('gdprPreferences', JSON.stringify(preferences), {
      expires: 365, // Gyldig i 1 år
      sameSite: 'Strict',
      secure: true,
    });
    setIsPreferencesSet(true); // Skjul modal
    setShowModal(false);
  };

  return (
    <div className="gdpr-container">
      <button className="btn link" onClick={() => setShowModal(true)}>
        Åbn cookieindstillinger
      </button>

      <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false}>
        <Modal.Header className='bg-color20 txt-color20'>
          <Modal.Title>Cookieindstillinger</Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-color70'>
          <p>
            Vi bruger cookies til at personalisere indhold og reklamer, levere sociale
            mediefunktioner og analysere vores trafik. Du kan justere dine præferencer nedenfor.
          </p>
          <div className="cookie-settings">
            <ul>
              <li>
                <label>
                  <input type="checkbox" checked disabled />{' '}
                  Nødvendige cookies (altid aktiveret)
                </label>
              </li>
              <li>
                <label>
                  <input
                    type="checkbox"
                    checked={preferences.analytics}
                    onChange={(e) =>
                      handlePreferenceChange('analytics', e.target.checked)
                    }
                  />{' '}
                  Analysecookies
                </label>
              </li>
              <li>
                <label>
                  <input
                    type="checkbox"
                    checked={preferences.advertising}
                    onChange={(e) =>
                      handlePreferenceChange('advertising', e.target.checked)
                    }
                  />{' '}
                  Reklamecookies
                </label>
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer className='bg-color20 txt-color20'>
          <Button variant="primary" onClick={savePreferences}>
            Gem præferencer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GDPRModal;

import React from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { Container, Form, Button, Alert } from "react-bootstrap";
import AppFrame from "./AppFrame";

const Kontakt = () => {
  const { register, handleSubmit, reset, formState: { errors, isSubmitSuccessful } } = useForm();

  const onSubmit = async (data: any) => {
    try {
      await emailjs.send(
        "service_pr1z3ps",
        "template_zrslk4f",
        data,
        "3ItWq0TdKqfRVOdz3"
      );
      reset();
    } catch (error) {
      console.error("Fejl ved afsendelse:", error);
    }
  };

  return (
    <AppFrame children={
      <>
        <Container className="p-4 mt-5 rounded shadow bg-color70">
          <h1>Kontakt os</h1>
          <p>Du er velkommen til at kontakte os via formularen nedenfor.</p>

          {isSubmitSuccessful && <Alert variant="success">Din besked er sendt!</Alert>}

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <Form.Label>Navn</Form.Label>
              <Form.Control
                type="text"
                placeholder="Indtast dit navn"
                {...register("name", { required: "Navn er påkrævet" })}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name?.message?.toString()}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Indtast din email"
                {...register("email", { required: "Email er påkrævet" })}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.message?.toString()}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Besked</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Indtast din besked"
                {...register("message", { required: "Besked er påkrævet" })}
                isInvalid={!!errors.message}
              />
              <Form.Control.Feedback type="invalid">
                {errors.message?.message?.toString()}
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit">
              Send besked
            </Button>
          </Form>
        </Container>
        <Container className="mt-5">
          <h2>Kontaktoplysninger</h2>
          <p><strong>Adresse:</strong> Ørvigvej 22, 6040 Egtved</p>
          <p><strong>Telefon:</strong> +45 27 85 20 90</p>
          {/*<p><strong>Email:</strong> kontakt@eksempel.dk</p>*/}
        </Container>
      </>
    } />
  );
};

export default Kontakt;

import AppFrame from './AppFrame';
import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/hero';
import VideoSection from '../components/video-section';
import ImageSection from '../components/image-section';
import ImageSectionV2 from '../components/image-section-v2';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';

const ForsidePage: React.FC = () => {
    return (
        <>
           <AppFrame children={
                <>  
                    <Helmet>
                        <title>Weng Consult - Forside</title>
                        <meta name="description" content="Weng Consult hjælper din organisation med at kortlægge, optimere og automatisere forretningsprocesser. Få mere fra hånden med færre ressourcer." />
                        <link rel="canonical" href={window.location.href} />
                    </Helmet>
                    <Hero 
                        overskrift="Få mere fra hånden med færre ressourcer" 
                        heroText="Vi udvikler værktøjer til nemt og hurtigt at vurdere om en automatisering er værd at implementere." 
                        heroIMG="/pics/online-meeting.jpg"
                        firstPath="/rapportgenerator"
                        secondPath="/how-to"
                        firstButtonText='Prøv vores værktøj'
                        secondButtonText='Læs mere'
                        bg='var(--70color)'
                        mirrorImage={true}
                        reverseLayout={true}
                        
                    />
                    <ImageSectionV2
                        title="Er automatisering en god investering for din virksomhed?" 
                        content={<>
                            <p>
                                <em>Forestil dig, at du på få minutter kan få indsigt i, hvor meget dine manuelle processer koster og finde ud af, om automatisering kan betale sig for netop din virksomhed.</em>
                            </p>
                            <p>
                                Det kan ofte være en udfordring at afgøre, om en automatisering er en investering, der kan betale sig. For eksempel kan en virksomhed, der manuelt administrerer kundeoplysninger, opleve udfordringer med fejl og forsinkelser. Ved at investere i et automatiseret CRM-system kan de reducere disse fejl og spare tid, selvom opstartsomkostningerne kan virke høje.
                                <br/>
                                På den ene side kan det være en stor investering, såsom betydelige opstartsomkostninger til software eller konsulentbistand, men på den anden side kan det også føre til store besparelser, for eksempel ved reduceret tidsforbrug og færre fejl i processerne.
                                <br/>
                            </p>
                            <p>
                                Når vi står midt i at drive en forretning, kan vi nemt komme til at glemme, at den nuværende måde tingene gøres på også har en omkostning.
                            </p>
                            <p>
                                Sådan er det med vaner og arbejdsgange. Vi bliver så vant til at bære udgiften for vores nuværende måder at gøre ting på. Over tid kommer vi til at tænke på dem som udgiftsneutrale.
                            </p>
                            <p>
                                For nogen vil eksempelvis 50.000 kr. for en IT-løsning virke som en stor udgift, men ofte er det fordi vi glemmer, at den faktisk skal ses op imod de 100.000 kr. vi hvert år bruger på at løse opgaven manuelt.
                            </p>
                            <p>
                                Derfor har vi udviklet et værktøj, der kan hjælpe dig med at få klarsyn.
                                <br/>
                                Vores værktøjer er lavet til at holde udgiften for dine manuelle processer op mod udgiften for en automatiseret proces.
                                <br/>
                                Du vil få en fornemmelse af, hvor hurtigt investeringen vil begynde at generere overskud gennem brugervenlige data-visualiseringer og detaljerede rapporter, som gør det nemt at træffe informerede beslutninger.
                            </p>
                        </>} 
                        imageLink="/Process-map1.webp"
                        primText="Kontakt os"
                        secText="Læs mere"
                        className='mt-5 bg-color70'
                        firstPath='/kontakt'
                        secondPath='/om-os'
                        reverseLayout={true}
                    /> 
                    <ImageSectionV2 
  title="Tag hul på fremtidens procesoptimering og automatisering!" 
  content={
    <>
        <p>
            <em>Er du nysgerrig på, om automatisering af manuelle kontoropgaver er nøglen 
            til at frigøre ressourcer og styrke din virksomheds bundlinje?</em></p>
            <p>Med vores skræddersyede rapport-generator kan du få svaret på få minutter.
        </p>
        <p>
            Værktøjet er designet til beslutningstagere i små og mellemstore 
            virksomheder, der vil sætte turbo på væksten gennem effektivitet 
            og digitalisering.
        </p>
        <p style={{fontWeight: 'bold', fontSize: '1.2rem'}}>
            Besvar blot 3-5 enkle spørgsmål, og få en personlig rapport, der 
            afslører:
        </p>
            <ul className='ms-4'>
                <li>
                    <strong>Hvor automatisering kan give hurtige gevinster</strong>
                    <br />
                    Se, hvilke konkrete manuelle opgaver der er mest oplagte at optimere.
                </li>
                <li>
                    <strong>Hvor meget tid og hvor mange penge du kan spare</strong>
                    <br />
                    Få et klart tal på bordet, så du kan vurdere den faktiske effekt.
                </li>
                <li>
                    <strong>Din potentielle ROI (Return on Investment)</strong>
                    <br />
                    Opdag, hvor hurtigt investeringen betaler sig hjem.
                </li>
            </ul>
    </>
  }
  imageLink="/chaos-to-order1.webp"
  primText="Kontakt os"
  secText="Læs mere"
  className="mt-5 bg-color70"
  firstPath="/kontakt"
  secondPath="/om-os"
  reverseLayout={false}
/>

                    <Container className='mt-5'>  
                        <Row>
                            <Col className='rounded shadow' md={8} style= {{backgroundColor: 'var(--70color)'}}>
                                <div className='p-4'>
                                    <h3 className=''>Hvorfor vælge os?</h3>
                                    <p>
                                        Vi kombinerer ekspertise inden for procesoptimering, automatisering og forretningsudvikling for at levere en løsning, der er enkel at bruge og giver dig klare svar.<br/>
                                        Vores rapporter hjælper dig med at tage datadrevne beslutninger om, hvor det er mest rentabelt at implementere automatiseringsteknologier som RPA (Robotic Process Automation).
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container className='mt-5 '>  
                        <Row className='flex-row-reverse'>
                            <Col className='rounded shadow' md={8} style= {{backgroundColor: 'var(--70color)'}}>
                                <div className='p-4'>
                                    <h3 className=''>Hvem er dette for?</h3>
                                    <ul>
                                        <li><strong>Virksomhedsejere:</strong> Der vil maksimere produktiviteten og reducere spild.</li>
                                        <li><strong>Procesansvarlige:</strong> Der vil identificere og forbedre ineffektive arbejdsprocesser.</li>
                                        <li><strong>Forretningsudviklere:</strong> Der vil drive innovation og skabe vækst.</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container className='mt-5'>  
                        <Row>
                            <Col className='rounded shadow' md={8} style= {{backgroundColor: 'var(--70color)'}}>
                                <div className='p-4'>
                                    <h3 className=''>Så nemt er det</h3>
                                    <ol>
                                        <li>Svar på få spørgsmål om dine nuværende processer.</li>
                                        <li>Modtag en rapport, der gør det tydeligt, hvad din nuværende proces koster, og om et tilbud på en automatisering kan betale sig.</li>
                                        <li>Brug rapporten som grundlag for at tage de næste skridt mod smartere arbejdsgange.</li>
                                    </ol>
                                    <p><strong>Start din rejse mod smartere arbejdsgange i dag!</strong></p>
                                    <Button variant="primary btn-lg" href="/rapportgenerator"> 
                                        Klik her og få din gratis rapport
                                    </Button> 
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {/* <Hero 
                        overskrift="Få mere fra hånden med færre ressourcer" 
                        heroText="Ekspert i at hjælpe din organisation til at strømligne forretnings-processer og udnytte autmatiserings-potentiale." 
                        heroIMG="/hero_img.png"
                        firstPath="/kontakt"
                        secondPath="/om-os"
                        firstButtonText='Kontakt Weng Consult'
                        secondButtonText='Læs mere'
                        bg='var(--70color)'
                        mirrorImage={true}
                        reverseLayout={true}
                        
                    /> */}
                    {/* <ImageSection 
                        title="Første skridt mod effektivisering er kortlæggning af processerne" 
                        text={<>
                        <p>Hvis du gerne vil have en effektiv forretning, er det alpha og omega at har styr på din organsiations værdikæde!</p>
                        <p>Vi hjælper dig med at kortlægge dine processer, rafinerer dem, og skabe overblik over de krav netop din organisation har til software, IT-system eller RPA-løsning.</p>
                        <p className='px-5'>
                            <em>Har i styr på jeres nuværende arbejdsgange, men planlægger at omlægge til automaiske processer?</em><br/>
                            <Link to={"/"} >Sådan planlægger i automatisering med succes.</Link>
                        </p>
                        <p>På den måde ved i nøjagtig hvad i har brug for, og kan undgå at investere i unødvendige løsninger, eller blive forført af udbydere som ikke kan levere det i har brug for.</p>
                        <p>Vi hjælper uanset om i ingen erfaring har med automation, eller om i allerede har eksisterende systemer i vil optimerer.</p>
                        </>} 
                        imageLink="/Process-map1.webp"
                        primText="Kontakt os"
                        secText="Læs mere"
                        className='mt-5'
                        bg='var(--70color)'
                        firstPath='/kontakt'
                        secondPath='/om-os'
                        reverseLayout={false}
                    /> */}
                    {/* <VideoSection 
                        title="Kan det betale sig?" 
                        text={<>
                        <p><b>- Er du i tvivl om hvorvidt det kan betale sig at automatisere en proces?</b></p>
                        <p>Kunne du tænke dig at få hurtig afklaring inden for få minutter?</p>
                        <p>Se videoen og få et hurtigt indblik i hvordan, Weng Consult process-rapport værktøj kan give dig et overblik på få minutter.</p>
                        </>} 
                        videoLink="/video/Intro.mp4"
                        primText="Kontakt os"
                        secText="Læs mere"
                        className='mt-5'
                        bg='var(--70color)'
                        firstPath='/kontakt'
                        secondPath='/om-os'
                        reverseLayout={true}
                    /> */}
                </>
           } /> 
        </>
    );
}

export default ForsidePage;
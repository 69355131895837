import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import NavbarLink from "./navbarLinks";
import { NavLink } from "../models/navLinksModel";

interface NavbarMainProps {
    brandName: string;
    imgPath?: string;
    links: NavLink[]; // Brug af NavLink modellen
}

/**
 * NavbarMain component renders a responsive navigation bar using React Bootstrap.
 */
const NavbarMain: React.FC<NavbarMainProps> = ({ brandName, imgPath, links }) => {
    const leftLinks = links.filter(link => link.align !== "right");
    const rightLinks = links.filter(link => link.align === "right");

    return (
        <Navbar expand="lg" style={{ zIndex: 1 }}>
            <Container>
                <Navbar.Brand href="/">
                    {imgPath && (
                        <img
                            src={imgPath}
                            width="30"
                            height="30"
                            className="d-inline-block align-top me-1"
                            alt="Brand logo"
                        />
                    )}
                    {brandName}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    {/* Venstrejusterede links */}
                    <Nav className="me-auto">
                        {leftLinks.map((link, index) => (
                            <NavbarLink
                                key={index}
                                label={link.label}
                                path={link.path}
                                dropdown={link.dropdown}
                                page={link.page}
                            />
                        ))}
                    </Nav>
                    {/* Højrejusterede links */}
                    <Nav className="ms-auto">
                        {rightLinks.map((link, index) => (
                            <NavbarLink
                                key={index}
                                label={link.label}
                                path={link.path}
                                dropdown={link.dropdown}
                                page={link.page}
                            />
                        ))}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavbarMain;

import AppFrame from "./AppFrame";
import { Container, Row, Col, Stack } from "react-bootstrap";
import ImageSectionV2 from "../components/image-section-v2";
import { Helmet } from "react-helmet";

const container_styling = "rounded shadow bg-color70 p-5 mb-4" + " ";


const Kun5MinArtikle = () => {
  return (
    <AppFrame children={<div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <Helmet>
        <title>Hvor meget koster dine "små" processer i skjulte ressourcer?</title>
        <meta name="description" content="Hvor mange 'fem-minutters-opgaver' findes der i din organisation? Og har du nogensinde tænkt over, hvad de egentlig koster i det lange løb? Forestil dig en snebold, der starter lille, men vokser eksponentielt, hver gang den ruller. De mange små opgaver akkumulerer og kan nemt stjæle hundredevis af timer hvert år – timer, der kunne bruges på at skabe reel værdi for din virksomhed."></meta>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Container className={`rounded shadow p-5 bg-color70`}>
        <h1><strong>Hvor meget koster dine "små" processer i skjulte ressourcer?</strong></h1>
        <div className="d-flex align-items-center mb-2">
          <img
          src="hero_img.png"
          alt="Jonas Weng"
          style={{
            borderRadius: '50%',
            width: '3em',
            height: '3em',
            marginRight: '10px',
          }}
          />
          <Stack gap={0}>
          <p style={{ fontSize: '0.9em', fontStyle: 'italic' }} className="m-0 p-0" >Af: Jonas Weng</p>
          <p style={{ fontSize: '0.9em', fontStyle: 'italic' }} className="m-0 p-0">Dato: 04/01-2025</p>
          </Stack>
        </div>
        <p><em>
          Hvor mange 'fem-minutters-opgaver' findes der i din organisation? Og har du nogensinde tænkt over, hvad de egentlig koster i det lange løb?<br/> Forestil dig en snebold, der starter lille, men vokser eksponentielt, hver gang den ruller. <br/> De mange små opgaver akkumulerer og kan nemt stjæle hundredevis af timer hvert år – timer, der kunne bruges på at skabe reel værdi for din virksomhed.
        </em></p>
        <img
          src="pics/snebold-af-opgaver.webp"
          alt="En snebold af opgaver, som vokser sig større og større, imens den ruller over medarbejdere"
          className="rounded shadow img-fluid responsive-img-right"
        />
        <h2>De skjulte omkostninger ved små opgaver</h2>
        <p>
          Jeg husker tydeligt en samtale, jeg havde med en tidligere leder for et par år siden. Vi sad på kontoret og drøftede de månedlige lønindberetninger fra vores eksterne konsulenter. "Det tager jo kun fem minutter pr. indberetning," sagde han med et smil. <br/>"Hvor ofte gør vi det? Et par gange om måneden? Det kan næppe betale sig at automatisere."
        </p>
        <p>
          Han trak på skuldrene med en ligegyldig mine, imens han smilede godmodigt. "Indberetningerne kommer ind på vidt forskellige måder," fortsatte jeg – nogle med vedhæftede filer, andre direkte skrevet i mails. Ofte mangler der oplysninger, og det kræver, at vi skriver frem og tilbage. Det betyder, at både jeg og de andre koordinatorer bruger 4-8 timer hver måned på at samle oplysningerne. Og så er der regnskabsafdelingen, som bruger to fulde arbejdsdage hver måned på manuelt at behandle indberetningerne."
        </p>
        <p>
          Min leder løftede et øjenbryn. "Men er det ikke stadig en relativt kort tid pr. indberetning?"
        </p>
        <p>
          "Det er, hvis alt går vel," svarede jeg, "og det gør det ikke altid. Hvis vi kunne strømline processen, måske med en portal som tjekker, om informationen er korrekt og fyldestgørende, inden den bliver sendt ind – hvor konsulenterne indtaster data på samme måde hver gang – kunne vi reducere fejl og spare en masse tid."
        </p>
        <p>
          Min leder smilede og nikkede høfligt, imens hans øjne blev fjerne. Jeg lænede mig frem i et forsøg på at fange hans opmærksomhed igen. "Og hvis vi så undersøgte en RPA-løsning, kunne vi måske automatisere hele behandlingen. Det ville frigive tid for os alle – og vi kunne bruge den på vigtigere opgaver."
        </p>
        <p>
          Forud for mødet havde jeg forberedt en beregning, der tydeligt viste omfanget af vores tidsforbrug. Jeg præsenterede tallene med en simpel oversigt: To fulde arbejdsdage for regnskabsafdelingen og 4-8 timer hver måned for koordinatorerne. Disse tal løb hurtigt op i en årlig udgift på over 215.000 kr. Denne visuelle tilgang hjalp med at gøre omkostningerne konkrete og sværere at ignorere. Med en medarbejderkostpris på mellem 300 og 345 kr. i timen koster det virksomheden omkring 9.000 kr. om måneden alene for dette arbejde. Over et helt år landede vi på lige knap 110.000 kr. – og det er kun regnskabsafdelingens tid, vi taler om. Når vi inkluderede de fire koordinatorers arbejde med at indsamle og validere data, stiger beløbet til lidt over 215.000 kr. om året!
        </p>
        <p>
          Min leder lyttede opmærksomt, men jeg kunne se, at han stadig var skeptisk. Hans øjne rettede sig vurderende imod papiret foran ham. "Det er klart, at det er en udgift," sagde han og lænede sig tilbage i stolen. "Men er det nok til at retfærdiggøre omkostningerne ved at udvikle en ny proces og potentielt købe software? Vi taler jo stadig om opgaver, der fungerer – omend ikke perfekt."
        </p>
        <p>
          Jeg tog en dyb indånding og forsøgte at forklare igen. "Det handler ikke kun om kroner og ører – det handler om arbejdsglæde og effektivitet. De her opgaver skaber frustrationer og tager tid fra mere værdifulde opgaver. Og hvis vi kan undgå fejl, sparer vi potentielt endnu flere penge."
        </p>
        <p>
          Han nikkede langsomt og kiggede tænksomt på mig. "Jeg kan godt se, hvad du mener. Men lige nu er der så mange andre ting på vores bord. Lad os tage det op igen til næste år og se, om det giver mening at gøre noget ved det der."
        </p>
        <p>
          Jeg forlod mødet med en mat fornemmelse. Jeg vidste, at det var blevet fejet videre til næste år, og næste år ville der ske det samme.
        </p>
        <p>
          Særligt i en virksomhed, hvor vi hver dag talte om, hvor travlt vi havde, og hvordan vi konstant løb stærkt uden at kunne nå alle opgaverne fyldestgørende. Beslutningen om ikke at gøre tingene mere effektivt føltes derfor endnu mere frustrerende i en virksomhed, hvor manglen på tid og ressourcer var et gennemgående tema.
        </p>
        <p>
          Men ineffektive arbejdsgange koster ikke kun i medarbejdertid og frustration – de sætter også virksomhedens konkurrenceevne på spil. Mens andre forbedrer og strømliner deres processer, risikerer vi at falde bagud og miste værdifulde muligheder.
        </p>
        <h2>Konklusion</h2>
        <p>
          Det var her, jeg tog beslutningen. Jeg indså, at hvis jeg blev i en virksomhed, der ikke ville handle, ville jeg selv blive fanget i status quo.<br/>
          Jeg ville ikke acceptere ineffektive arbejdsgange, der stjal tid, energi og arbejdsglæde – for mig eller for andre.<br/>
          Jeg ville dedikere min karriere til at hjælpe virksomheder, som var parat til at optimere deres processer og gøre hverdagen lettere for medarbejderne. For procesoptimering og automatisering handler ikke kun om at spare penge – det handler om at skabe bedre arbejdsdage, reducere stress og løfte både produktivitet og arbejdsglæde.<br/>
          Det er klart der også bør være et økonomisk fundament at stå på, men der er langt flere gevinster at hente, end tallene på bundlinjen kan indikere.
        </p>
        <p>
          Når du sidder der og tænker, "Det kan vi tage senere," så spørg dig selv: Hvad koster det at vente?<br/>
          Tag et ærligt kig på jeres nuværende processer, og overvej, hvor I kunne frigøre tid, reducere fejl og forbedre arbejdsglæden.<br/>
          Start med en mindre proces, identificer forbedringsmuligheder, og sæt en plan i gang – i dag.<br/>
          I dag er det tid til at tage beslutningen – din virksomhed og dine medarbejdere vil takke dig for det.
        </p>
      </Container>    
    </div>
    } />
  );
};

export default Kun5MinArtikle;

import AppFrame from './AppFrame';
import ImageSectionV2 from '../components/image-section-v2';

const AboutUs = () => {
  return (
    <AppFrame children={
    <>
      <h1 className="text-center my-5"><strong>Om os</strong></h1>
      <ImageSectionV2 primText='Kontakt' firstPath='/kontakt' imageLink='/pics/online-meeting.jpg' title='Vi skaber forbindelse immellem IT og mennesker' className="my-5 bg-color70" content={
        <>
          <p>
            Velkommen til Weng Consult. Vi specialiserer os i at hjælpe små og mellemstore 
            virksomheder med at optimere deres arbejdsprocesser og implementere automatisering 
            i kontorarbejde. Vores mål er at give virksomhedsejere de værktøjer og løsninger, de 
            har brug for, til at arbejde smartere og mere effektivt.
          </p>
          <p>
          <strong>Det gør vi ved at:</strong>
          </p>
          <ol>
            <li>Gøre det tydeligt hvad jeres nuværende processer koster at drive, så i har et reelet sammenligningsgrundlag når i indhenter tilbud fra udbydere.</li>
            <li>Identificerer og kortlægge de processer som skaber mest værdi for jer, og jeres kunder, så tilbud på den konkrete løsning bliver nemmere at indhente og har størst chance for succes ved implimentering.</li>
            <li>Rådgiver automatiserings-værktøjer og AI-platforme, så i er sikrede at i tager den helt rigtige løsning i brug.</li>
            <li>Yder rådgiving og undervinsing til ledelse samt medarbejdere i implimenteringsfasen.</li>
          </ol>
          <h3>Vores Mission</h3>
          <p>
            Vi ønsker at forenkle hverdagen for virksomheder ved at reducere gentagne og 
            tidskrævende opgaver, så I kan fokusere på det, der virkelig betyder noget – 
            jeres forretning og at skabe værdi for jeres kunder.
          </p>
          <h3>Hvorfor vælge os?</h3>
          <ul>
            <li>
              <strong>Ekspertise:</strong> Med mange års erfaring inden for automatisering 
              og procesoptimering tilbyder vi skræddersyede løsninger, der passer til din virksomhed.
            </li>
            <li>
              <strong>Tilpassede løsninger:</strong> Vi forstår, at hver virksomhed er unik. 
              Derfor tager vi os tid til at forstå dine behov og rådgiver om løsninger, der matcher dine mål.
            </li>
            <li>
              <strong>Pålidelig support:</strong> Vi er her for at sikre, at implementeringen går glat, 
              og at du får det fulde udbytte af vores løsninger.
            </li>
          </ul>
          <h3>Vores Historie</h3>
          <p>
            Weng Consult blev grundlagt i 2024 af Jonas Weng, med en vision om at revolutionere måden, 
            små og mellemstore virksomheder arbejder på. <br/>
            Automatisering og digitalisering af arbejdsgange skulle ikke være forbeholdt store virksomheder
            med store budgetter, de skulle også komme de små
            virksomheder og foreninger til gode. Siden har vi hjulpet flere virksomheder og foreninger med at
            automaisere deres drift så de kan fokusere på det de brænder for, uden at skulle spilde tiden på
            små administrative opgaver der skal huskes, eller besværlige koordineringsprocesser, eller økonomistyring.
          </p>
          <h3>Kontakt os</h3>
          <p>
            Er du klar til at optimere din virksomhed? Kontakt os i dag, og lad os finde den bedste 
            løsning til dig.
          </p>
        </>
      }/>
    </>
    }/>
  );
};

export default AboutUs;

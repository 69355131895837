import AppFrame from './AppFrame';
import Hero from '../components/hero';
import VideoSection from '../components/video-section';
import ImageSectionV2 from '../components/image-section-v2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const OmRapportGeneratorPage: React.FC = () => {
    return (
        <>
           <AppFrame children={
                <>
                <Helmet>
                    <title>Om rapportgeneratoren</title>
                    <meta name="description" content="Hvordan fungerer Weng Consults rapportgenerator? Få et hurtigt overblik over hvordan du kan vurdere om det kan betale sig at automatisere en proces." />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                    <h1 className="text-center mt-3 mb-4">Sådan bruger du Rapport-generatoren</h1>
                    
                    {/* <VideoSection 
                        title="Kan det betale sig?" 
                        text={<>
                        <p><b>- Er du i tvivl om hvorvidt det kan betale sig at automatisere en proces?</b></p>
                        <p>Kunne du tænke dig at få hurtig afklaring inden for få minutter?</p>
                        <p>Se videoen og få et hurtigt indblik i hvordan, Weng Consult process-rapport værktøj kan give dig et overblik på få minutter.</p>
                        </>} 
                        videoLink="/video/Intro.mp4"
                        primText="Kontakt os"
                        secText="Læs mere"
                        className='mt-5'
                        bg='var(--70color)'
                        firstPath='/kontakt'
                        secondPath='/om-os'
                        reverseLayout={true}
                    /> */}
                   
                    <ImageSectionV2 
                        title="Hvordan fungerer Rapport-generatoren?" 
                        content={<>
                            <p>
                                <em>Besvar 3-5 lette spørgsmål og få en rapport på 2 min.</em>
                            </p>
                            <p>
                                Det er nemt at komme igang. 
                                <br/>
                                Du skal blot indtaste oplysninger om 3 nøgleparametre, og så vil vores værktøj generere en rapport for dig.<br/>
                                <br/>

                                <strong>Det skal du have klar:</strong><br/>
                                - Tid der bruges på opgaven (pr. dag, uge eller måned)<br/>
                                - Antal medarbejdere som beskæftiger sig med opgaven<br/>
                                - Medarbejderens gennemsnitlige timeløn<br/>
                                - Din målsætning (Hvor stor en del af processen ønsker du at overlade til en robot)<br/>
                                - <em>(valgfrit)</em> Oplys et tilbud fra en leverandør - for at se din ROI over tid. (Return on investment)
                            </p>
                            <p>
                                Det er alt du skal bruge for at få en rapport der viser dig om det kan betale sig at automatisere.
                            </p>
                            <p>

                            </p>
                        </>} 
                        imageLink="pics/glad-kvinde-med-laptop.png"
                        className='bg-color70'
                    />   
                </>
           } /> 
        </>
    );
}

export default OmRapportGeneratorPage;
/**
 * ImageSection component renders a section with an image and accompanying text.
 * It allows for a customizable layout with optional reverse layout and background color.
 *
 * @component
 * @example
 * // Example usage:
 * <ImageSection
 *   title="Sample Title"
 *   text="This is a sample text accompanying the image."
 *   imageLink="https://sampleimagelink.com"
 *   reverseLayout={true}
 *   primText="Primary Button"
 *   firstPath="/primary-path"
 *   secText="Secondary Button"
 *   secondPath="/secondary-path"
 *   className="custom-class"
 *   bg="#f0f0f0"
 * />
 *
 * @param {Object} props - The properties object.
 * @param {string} props.title - The title text to display.
 * @param {string} props.text - The descriptive text to display.
 * @param {string} props.imageLink - The URL link to the image.
 * @param {string} props.imageAlt - The alt text for the image.
 * @param {string} [props.imageWidth='33%'] - The width of the image.
 * @param {boolean} [props.reverseLayout=false] - If true, the layout will be reversed.
 * @param {string} props.primText - The text for the primary button.
 * @param {string} props.firstPath - The URL path for the primary button action.
 * @param {string} props.secText - The text for the secondary button.
 * @param {string} props.secondPath - The URL path for the secondary button action.
 * @param {string} [props.className] - Additional class names for custom styling.
 * @param {string} [props.bg='transparent'] - Background color for the container.
 * @param {number} [props.maxParagraphsOnImageSide=2] - Maximum number of paragraphs to display on the image side (default is 2).
 *
 * @returns {JSX.Element} The rendered ImageSection component.
 */
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

interface ImageSectionProps {
    title: string;
    content: string | React.ReactNode;
    imageLink: string;
    imageAlt?: string;
    reverseLayout?: boolean;
    primText?: string;
    firstPath?: string;
    secText?: string;
    secondPath?: string;
    className?: string;
}

const ImageSectionV2: React.FC<ImageSectionProps> = ({ title, content, imageLink, imageAlt="Et billede", reverseLayout=false, primText, firstPath, secText, secondPath, className}) => {
    if ((primText && !firstPath) || (!primText && firstPath)) {
        console.error("If 'primText' is provided, 'firstPath' must also be provided, and vice versa.");
    }

    if ((secText && !secondPath) || (!secText && secondPath)) {
        console.error("If 'secText' is provided, 'secondPath' must also be provided, and vice versa.");
    }

    return (
        <div className="">
            <Container className={`rounded shadow p-5 ${className}`} style={{ minHeight: '' }}>
                <img
                    src={imageLink}
                    alt={imageAlt}
                    className={`rounded shadow img-fluid ${reverseLayout ? 'responsive-img-left' : 'responsive-img-right'} `}
                />
                <h2>{title}</h2>
                {content}
                <div className={`mx-5 mt-4 ${reverseLayout ? 'text-end' : 'text-start'}`}>
                    {primText && firstPath ? <Button className="m-2" href={firstPath}>{primText}</Button> : null}
                    {secText && secondPath ? <Button className="m-2" variant="outline-secondary" href={secondPath}>{secText}</Button> : null}
                </div>
            </Container>
        </div>
    );
};

export default ImageSectionV2;
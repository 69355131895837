import { formatNumberOneDecimal } from "./tidsFormatering";

/**
 * Calculates the Return on Investment (ROI) in percentage for a given period.
 *
 * @param {number} numberOfMonths - The number of months over which to calculate the ROI.
 * @param {number} monthlySavings - The savings per month.
 * @param {number} oneTimeCosts - The one-time costs.
 * @param {number} monthlyCosts - The recurring monthly costs.
 * @returns {number} The ROI in percentage.
 */
const calculateROI = (
    numberOfMonths: number,
    monthlySavings: number,
    oneTimeCosts: number,
    monthlyCosts: number
): number => {
    // Ensure all inputs are numbers
    const validNumberOfMonths = Number(numberOfMonths);
    const validMonthlySavings = Number(monthlySavings);
    const validOneTimeCosts = Number(oneTimeCosts);
    const validMonthlyCosts = Number(monthlyCosts);

    const totalSavings = validMonthlySavings * validNumberOfMonths;
    const totalCosts = validOneTimeCosts + (validMonthlyCosts * validNumberOfMonths);

    if (totalCosts === 0) {
        return 0; // Return 0 or any other default value when totalCosts is 0
    }

    const roi = ((totalSavings - totalCosts) / totalCosts) * 100;
    const formattedROI: number = parseFloat(formatNumberOneDecimal(roi) as string);

    return formattedROI;
};

export default calculateROI;

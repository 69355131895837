
import { stringify } from "querystring";
import { ProcessFormValues } from "../models/processFormModel";
import { ProcessRapport } from "../models/rapportModel";
import { sekunderDagligt, tidTilUger, tidTilMåneder, tidTilÅr } from "./frekvensUdregner";
import { pengePrDag, pengePrUge, pengePrMåned, pengePrÅr } from "./pengeUdregner";

/**
 * Generates a process report based on employee frequency and salary.
 *
 * @param {ProcessFormValues} input - The input values for the process form.
 * @returns {ProcessRapport} - The generated process report.
 *
 * @description
 * Denne funktion tager inputværdier fra en procesformular og genererer en rapport, der indeholder basisinformationer, omfang af processen og økonomiske beregninger. 
 * Rapporten inkluderer også estimerede reduktioner i tid og penge baseret på en given procentvis reduktion.
 *
 * @example
 * const input: ProcessFormValues = {
 *   procesNavn: "Eksempel Proces",
 *   procesTid: { procesTidISekunder: 3600, frekvens: "dagligt" },
 *   antalMedarbejdere: 5,
 *   gennemsnitligLøn: 300,
 *   reduktionTidProcent: 10
 * };
 * const rapport = generateProcessRapportFromMedarbFrekvensLoen(input);
 * console.log(rapport);
 */
const generateProcessRapportFromMedarbFrekvensLoen = (input: ProcessFormValues): ProcessRapport => {
    //console.log("NY BEREGNING: -----------------------------------------------------------------------------")
    const { 
        procesNavn: name, 
        procesTid: { procesTidISekunder: sekunder, frekvens }, 
        antalMedarbejdere, 
        gennemsnitligLøn, 
        reduktionTidProcent,
        tilbudGivet: {tilbud, tilbudPris, løbendeUdgifterPrMdr, løbendeUdgifterPrÅr} = {}
        } = input;
    const rapportID = Math.random().toString(36).substring(7);
    const todaysDate = new Date();
    
    const løbendeUdgifterFremadrettetPrÅr = tilbud
        ? løbendeUdgifterPrMdr !== undefined && løbendeUdgifterPrMdr > 0
            ? løbendeUdgifterPrMdr * 12
            : løbendeUdgifterPrÅr !== undefined && løbendeUdgifterPrÅr > 0
                ? løbendeUdgifterPrÅr
                : 0
        : 0;

    const løbendeUdgifterFremadrettetPrMdr = tilbud
        ? løbendeUdgifterPrÅr !== undefined && løbendeUdgifterPrÅr > 0
            ? løbendeUdgifterPrÅr / 12
            : løbendeUdgifterPrMdr !== undefined && løbendeUdgifterPrMdr > 0
                ? løbendeUdgifterPrMdr
                : 0
        : 0;


    const nuværendeUdgifterPrMåned = pengePrMåned(gennemsnitligLøn, (tidTilMåneder(frekvens, sekunder)/3600) * antalMedarbejdere);
    

    const beregnInvesteringSameledeUdgifterForMåned = (antalMåneder: number): number => {
        //console.log("Tilbud:")
        //console.log(tilbud)
        //console.log("TilbudPris:")
        //console.log(tilbudPris)
        if (tilbudPris && tilbud) {
            if (tilbudPris > 0) {
                //console.log("TilbudPris er negativ, så den er sat til 0")
                const samledeUdgifter = tilbudPris + løbendeUdgifterFremadrettetPrMdr * antalMåneder;
                //console.log("Tilbud og tilbudPris samlet efter "+ antalMåneder +" måneder: ")
                //console.log(samledeUdgifter)
                return samledeUdgifter;
            } else {
                //console.log("TilbudPris er negativ, så den er sat til 0")
                const samledeUdgifter = løbendeUdgifterFremadrettetPrMdr * antalMåneder;
                //console.log("Tilbud og tilbudPris samlet efter "+ antalMåneder +" måneder: ")
                //console.log(samledeUdgifter)
                return samledeUdgifter;
            }
        } else {
            //console.log("Ingen beregning af samlede udgifter, da der ikke er givet tilbud")
            return Number(0);
        }
    }
    //Beregn ROI baseret på diferencen mellem nuværende udgifter og beregnInvesteringSameledeUdgifterForMåned
    const beregnInvesteringROI = (antalMåneder: number): number => {
        const UdgiftFør = nuværendeUdgifterPrMåned * antalMåneder;
        // console.log("UdgiftFør: " + nuværendeUdgifterPrMåned + " x " + antalMåneder + " = " + UdgiftFør);
        // console.log(UdgiftFør);
    
        const UdgiftEfter = beregnInvesteringSameledeUdgifterForMåned(antalMåneder);
        // console.log("Samlede udgifter for investering efter " + antalMåneder + " måneder: ");
        // console.log(UdgiftEfter);
    
        const differance = UdgiftFør - UdgiftEfter;
        // console.log("Differance: " + UdgiftFør + " - " + UdgiftEfter + " = " + differance);
        // console.log(differance);
    
        // Håndter deling med 0 for UdgiftFør
        if (UdgiftFør === 0) {
            // console.log("UdgiftFør er 0, returnerer 0 som ROI.");
            return 0;
        }
    
        // Beregn ROI
        const fortjensteDeltMedUdgiftFør = differance / UdgiftFør;
        const result = fortjensteDeltMedUdgiftFør * 100;
        return result;
    };
    


    const tilbudsPrisTilBeregning = (tilbudPris && tilbud ? tilbudPris : ((pengePrÅr(gennemsnitligLøn, (tidTilÅr(frekvens, sekunder)/3600) * antalMedarbejdere))/100 * reduktionTidProcent))
    const rapport: ProcessRapport = {
        basisInfo: {
            RapportID: rapportID,
            processNavn: name,
            processBeskrivelse: "Beskrivelse",
            generatedDate: todaysDate,
        },
        omfang: {
            process: {
                frekvens: frekvens,
                processTidSekunder: sekunder,
                antalProcesserOmÅret: 0,
                antalProcesserOmMåneden: 0,
                antalProcesserOmUgen: 0,
                antalProcesserOmDagen: 0,
                antalProcesserITimen: 0,
            },
            medarbejdere: {
                antalMedarbejdere: antalMedarbejdere,
                gennemsnitligTimeløn: gennemsnitligLøn,
                ferieUgerPrÅr: 0,
            },
            
        },
        økonomi: {
                gennemsnitligLøn: gennemsnitligLøn,
                tidBrugtPrÅr: (tidTilÅr(frekvens, sekunder)/3600) * antalMedarbejdere, 
                tidBrugtPrMåned: (tidTilMåneder(frekvens, sekunder)/3600) * antalMedarbejdere, 
                tidBrugtPrUge: (tidTilUger(frekvens, sekunder)/3600) * antalMedarbejdere,
                tidBrugtPrDag: (sekunderDagligt(frekvens, sekunder)/3600) * antalMedarbejdere, // sekunder delt med 3600 for at få timer * antal medarbejdere
                pengeBrugtPrÅr: pengePrÅr(gennemsnitligLøn, (tidTilÅr(frekvens, sekunder)/3600) * antalMedarbejdere),
                pengeBrugtPrMåned: pengePrMåned(gennemsnitligLøn, (tidTilMåneder(frekvens, sekunder)/3600) * antalMedarbejdere),
                pengeBrugtPrUge: pengePrUge(gennemsnitligLøn, (tidTilUger(frekvens, sekunder)/3600) * antalMedarbejdere),
                pengeBrugtPrDag: pengePrDag(gennemsnitligLøn, (sekunderDagligt(frekvens, sekunder)/3600) * antalMedarbejdere),
            },
        estimeretReduction: {
                estimeretReductionProcent: reduktionTidProcent,
                estimeretReductionProcessTidÅr: ((tidTilÅr(frekvens, sekunder)/3600) * antalMedarbejdere)/100 * reduktionTidProcent,
                estimeretReductionProcessTidMåned: ((tidTilMåneder(frekvens, sekunder)/3600) * antalMedarbejdere)/100 * reduktionTidProcent,
                estimeretReductionProcessTidUge: ((tidTilUger(frekvens, sekunder)/3600) * antalMedarbejdere)/100 * reduktionTidProcent,
                estimeretReductionMedarbejderTidÅr: ((tidTilÅr(frekvens, sekunder)/3600) * antalMedarbejdere)/100 * reduktionTidProcent,
                estimeretReductionMedarbejderTidMåned: ((tidTilMåneder(frekvens, sekunder)/3600) * antalMedarbejdere)/100 * reduktionTidProcent,
                estimeretReductionMedarbejderTidUge: ((tidTilUger(frekvens, sekunder)/3600) * antalMedarbejdere)/100 * reduktionTidProcent,
                estimeretReductionPengeÅr: (pengePrÅr(gennemsnitligLøn, (tidTilÅr(frekvens, sekunder)/3600) * antalMedarbejdere))/100 * reduktionTidProcent,
                estimeretReductionPengeMåned: (pengePrMåned(gennemsnitligLøn, (tidTilMåneder(frekvens, sekunder)/3600) * antalMedarbejdere))/100 * reduktionTidProcent,
                estimeretReductionPengeUge: (pengePrUge(gennemsnitligLøn, (tidTilUger(frekvens, sekunder)/3600) * antalMedarbejdere))/100 * reduktionTidProcent,
                estimeretReductionPengeDag: (pengePrDag(gennemsnitligLøn, (sekunderDagligt(frekvens, sekunder)/3600) * antalMedarbejdere))/100 * reduktionTidProcent,

        },
        tilbudPåLøsning: {
            tilbud: (tilbud? tilbud : false),
            tilbudPris: tilbudPris ? tilbudPris : 0,
            løbendeUdgifterFremadrettetPrÅr: løbendeUdgifterFremadrettetPrÅr,
        },
    };

    if (tilbud && tilbudsPrisTilBeregning > 0) {
        rapport.ROI = {
            monthsUntilEven: tilbudsPrisTilBeregning / ((pengePrMåned(gennemsnitligLøn, (tidTilMåneder(frekvens, sekunder)/3600) * antalMedarbejdere)) / 100 * reduktionTidProcent - (løbendeUdgifterFremadrettetPrÅr / 12)),
            ROI3mdr: beregnInvesteringROI(3),
            ROI6mdr: beregnInvesteringROI(6),
            ROI12mdr: beregnInvesteringROI(12),
            ROI2year: beregnInvesteringROI(24),
            ROI5year: beregnInvesteringROI(60),
        };
    }

    return rapport;
};

export default generateProcessRapportFromMedarbFrekvensLoen;
import './App.css';
import ForsidePage from './pages/ForsidePage';
import { BrowserRouter, Outlet } from "react-router-dom";
import RaportGenereator from './pages/RapportGenerator';
import NavbarMain from './components/navbarMain';
import RouteMap from './components/routeMap';
import FooterMain from './components/footerMain';
import OmRapportGeneratorPage from './pages/OmRapport-generatorenPage';
import { NavLink } from './models/navLinksModel';
import UnderKonstruktionPage from './pages/under_konstruktionPage';
import RPAArtikle from './pages/RPAArtikel';
import Kun5MinArtikle from './pages/Kun5Min';
import PersondataOgCookiesPage from './pages/PersondataOgCookiesPage';
import ArtikelTest from './pages/Artikel-test';
import Kontakt from './pages/KontaktPage';
import AboutUs from './pages/OmOs';
// Navbar links sat op i en funktion for at undgå skulle lave om på path i flere filer
export function navLinks(): NavLink[] {
  return [
    { label: 'Forside', path: '/', page: <ForsidePage /> },
    { label: 'Artikler', path: '' , dropdown: [
      { label: 'Fordele og udfordringer ved RPA', path: '/fordele-og-ulemper-ved-RPA', page: <RPAArtikle /> },
      { label: 'Hvor meget koster dine "små" processer i skjulte ressourcer?', path: '/hvor-meget-koster-dine-små-processer-i-skjulte-ressourcer', page: <Kun5MinArtikle /> },
      // { label: 'Husk de skjulte omkostninger', path: '/husk-de-skjulte-omkostinger', page: <ArtikelTest /> },
      // { label: 'ROI beregning af RPA-løsninger', path: '/roi-beregning-af-rpa', page: <UnderKonstruktionPage /> },
      ]
    },
    { label: 'Værktøjer', path: '' , dropdown: [ 
      { label: 'Sådan bruger du værktøjerne', path: '/how-to', page: <OmRapportGeneratorPage /> },
      //{ label: 'Sådan bruger du værktøjerne (Under konstruktion)', path: '/how-to', page: <UnderKonstruktionPage /> },
      { divider: true },
      { label: 'ROI-rapport', path: '/rapportgenerator', page: <RaportGenereator /> }
      ]  
    },
    { label: 'Om os', path: '/om-os', page: <AboutUs />, align: 'right' },
    { label: 'Kontakt', path: '/kontakt', page: <Kontakt />, align: 'right' },
    { label: 'GDPR', path: '/gdpr', page: <PersondataOgCookiesPage />, align: 'right' },
    // { label: 'Rapport-generator', path: '/rapportgenerator', page: <RaportGenereator /> }
  ];
};


function BasisLayout() {
  return (
    <>
      <NavbarMain 
        brandName='Weng Consult'
        imgPath='/logoRundVS.png'
        links={navLinks()}
      />
      <div >
      <Outlet />
      </div>
      <FooterMain />
    </>
  );
}

function App() {
  return (
    <>
      <BrowserRouter>
            <RouteMap links={navLinks()} basicLayout={<BasisLayout/>} />
      </BrowserRouter> 
    </>
  );
}

export default App;

import AppFrame from './AppFrame';
import ProcessForm from '../components/inputComponents/process-form';
import { Helmet } from 'react-helmet';

const RaportGenereator: React.FC = () => {
    return ( 
            <AppFrame children={
                <>
                    <Helmet>
                        <title>ROI-rapport-generator</title>
                        <meta name="description" content="Få en hurtig ROI-rapport på din proces og se om det kan betale sig at automatisere." />
                        <link rel="canonical" href={window.location.href} />
                    </Helmet>
                    <ProcessForm bg='var(--70color)' className='p-5 mt-3' /> 
                </>
            }/>       
    );
}

export default RaportGenereator;
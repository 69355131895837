import AppFrame from "./AppFrame";
import { Container, Row, Col, Card, Alert } from "react-bootstrap";

const PersondataOgCookiesPage = () => {
  return (
    <AppFrame children={
        <Container className="mt-5">
        <Row>
          <Col>
            <Card className="mb-3">
              <Card.Header as="h2">Persondata og Cookies</Card.Header>
              <Card.Body>
                <p>
                  Vi værner om din data og ønsker at være transparente omkring, hvordan vi indsamler og bruger den. 
                  Ved at benytte vores hjemmeside accepterer du brugen af cookies, som vi anvender til at forbedre 
                  din oplevelse.
                </p>
                <h3>Reklamer og Affiliate Links</h3>
                <p>
                  For at kunne tilbyde vores tjenester gratis, indeholder vores platform reklamer og affiliate links. 
                  Det betyder, at vi kan modtage en kommission, hvis du klikker på et link og foretager et køb. Dette 
                  påvirker ikke prisen for dig.
                </p>
                <Alert variant="info">
                  <strong>Bemærk:</strong> Reklamer og affiliate links er markeret for at sikre gennemsigtighed.
                </Alert>
                <h3>Cookies</h3>
                <p>
                  Cookies bruges til at gemme information om din interaktion med vores side, f.eks. hvilke værktøjer 
                  du bruger, og til at målrette reklamer. Du kan til enhver tid slette eller blokere cookies i din 
                  browser.
                </p>
                <p>
                  For mere information om vores brug af data og dine rettigheder, læs vores fulde privatlivspolitik herunder.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
            <Col>
                <Card>
                    <Card.Header as="h2">Privatlivspolitik</Card.Header>
                    <Card.Body>
                        <p>
                            På denne side finder du vores fulde og detaljerede privatlivspolitik. Vi ønsker at være
                            gennemsigtige med, hvordan vi behandler dine persondata og cookies, når du besøger vores hjemmeside.
                        </p>

                        <h3>Hvordan vi bruger reklamer og affiliate links</h3>
                        <p>
                            Vores hjemmeside finansieres delvist gennem reklamer og affiliate links. Dette betyder, at vi kan modtage en kommission, hvis du klikker på et link og foretager et køb. Disse links og reklamer påvirker ikke den pris, du betaler som bruger.
                        </p>
                        <p>
                            Alle reklamer og affiliate links på vores side er markeret for at sikre gennemsigtighed. Vi opfordrer dig til at kontakte os, hvis du har spørgsmål vedrørende dette.
                        </p>

                        <h3>Cookies og deres anvendelse</h3>
                        <p>
                            Cookies er små filer, der gemmes på din enhed for at forbedre din oplevelse og tilpasse indholdet på vores hjemmeside. De bruges blandt andet til at:
                        </p>
                        <ul>
                            <li>Huske dine præferencer og indstillinger.</li>
                            <li>Forstå, hvordan du interagerer med vores værktøjer og indhold.</li>
                            <li>Målrette reklamer baseret på dine interesser.</li>
                        </ul>
                        <p>
                            Du kan til enhver tid administrere eller slette cookies via dine browserindstillinger. Vær opmærksom på, at visse funktioner på hjemmesiden muligvis ikke fungerer korrekt uden cookies.
                        </p>

                        <h3>Dine rettigheder</h3>
                        <p>
                            Du har ret til at få indsigt i, hvilke data vi opbevarer om dig, samt til at få rettet eller slettet data, hvor det er muligt. Hvis du ikke er registreret bruger på hjemmesiden opbevarer vi ingen data om dig.
                        </p>

                        <h3>Kontakt</h3>
                        <p>
                            Hvis du har spørgsmål omkring vores privatlivspolitik, er du velkommen til at kontakte os (<a href="/kontakt">Se kontaktoplysninger)</a>.
                        </p>
                    </Card.Body>
                </Card>
            </Col>
      </Row>
      </Container>
    }/>
  );
};

export default PersondataOgCookiesPage;
import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import QuestionIconWithTooltip from './questionCirkelTooltip';
import { useNavigate, useLocation } from 'react-router-dom';

type ROIChartV2Props = {
    /** ROI efter $1 som en procentværdi */
    ROI3Mdr: number;
    /** ROI efter $1 som en procentværdi */
    ROI6Mdr: number;
    /** ROI efter 1 år som en procentværdi */
    ROI12Mdr: number;
    /** ROI efter 2 år som en procentværdi */
    ROI2Year: number;
    /** ROI efter 3 år som en procentværdi */
    ROI3Year: number;
    /** ROI efter 4 år som en procentværdi */
    ROI4Year: number;
    /** ROI efter 5 år som en procentværdi */
    ROI5Year: number;
};

const ROIChartV2: React.FC<ROIChartV2Props> = ({ ROI3Mdr, ROI6Mdr, ROI12Mdr, ROI2Year, ROI3Year, ROI4Year, ROI5Year }) => {
    // Definer data til grafen baseret på de props, der gives
    const data = [
        { month: 3, ROI: ROI3Mdr },
        { month: 6, ROI: ROI6Mdr },
        { month: 12, ROI: ROI12Mdr },
        { month: 24, ROI: ROI2Year },
        { month: 36, ROI: ROI3Year },
        { month: 48, ROI: ROI4Year },
        { month: 60, ROI: ROI5Year },
    ];

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const modalCanvasRef = useRef<HTMLCanvasElement | null>(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate(); // Bruges til at navigere til en anden URL
    const location = useLocation(); // Bruges til at håndtere browserens tilbage-knap


    
    const drawCanvas = (canvas: HTMLCanvasElement | null) => {
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                // Set canvas dimensions with scaling for high resolution
                const logicalWidth = 1920;
                const logicalHeight = 1080;
                canvas.width = logicalWidth;
                canvas.height = logicalHeight;

                // Clear canvas before drawing
                ctx.clearRect(0, 0, logicalWidth, logicalHeight);

                // Set up chart dimensions
                const width = logicalWidth; // logical width for drawing
                const height = logicalHeight; // logical height for drawing
                const maxLabelLength = Math.max(...data.map((d) => d.ROI.toFixed(0).length));
                const padding = 20 + maxLabelLength * 1.5 * 16; // Dynamically adjust padding based on the length of the longest label
                const chartWidth = width - padding * 2;
                const chartHeight = height - padding * 2;

                // Determine Y-axis interval using a dynamic approach
                let yInterval = 10;
                const minValueRaw = Math.min(...data.map((d) => d.ROI));
                const maxValueRaw = Math.max(...data.map((d) => d.ROI));
                let minValue = Math.floor(minValueRaw / yInterval) * yInterval;
                let maxValue = Math.ceil(maxValueRaw / yInterval) * yInterval;
                let numberOfPoints = (maxValue - minValue) / yInterval;

                let iteration = 0;
                while (numberOfPoints > 12) {
                    yInterval *= 2;
                    iteration++;
                    minValue = Math.floor(minValueRaw / yInterval) * yInterval;
                    maxValue = Math.ceil(maxValueRaw / yInterval) * yInterval;
                    numberOfPoints = (maxValue - minValue) / yInterval;
                }

                // Recalculate min and max values based on new interval
                minValue = Math.floor(minValueRaw / yInterval) * yInterval;
                maxValue = Math.ceil(maxValueRaw / yInterval) * yInterval;

                // Draw axes
                ctx.beginPath();
                ctx.lineWidth = 5; // Gør akselinjerne tykkere
                ctx.moveTo(padding, padding);
                ctx.lineTo(padding, height - padding);
                ctx.lineTo(width - padding, height - padding);
                ctx.stroke();

                // Draw Y-axis label
                ctx.fillStyle = '#000';
                ctx.textAlign = 'center';
                ctx.font = `2em Arial`; // Set text size and font in em based on heightEm
                ctx.fillText('ROI (%)', padding - 10, padding / 2); // Position label above Y-axis

                // Draw horizontal red line at y=0 if 0 is within range
                if (minValue < 0 && maxValue > 0) {
                    const zeroY = height - padding - ((0 - minValue) / (maxValue - minValue)) * chartHeight;
                    ctx.beginPath();
                    ctx.moveTo(padding, zeroY);
                    ctx.lineTo(width - padding, zeroY);
                    ctx.strokeStyle = 'red';
                    ctx.lineWidth = 4; // Gør den røde linje tykkere
                    ctx.stroke();

                    // Draw label above red line
                    ctx.fillStyle = 'red';
                    ctx.textAlign = 'right';
                    // Text size can be adjusted here with ctx.font in em to match the website's text size
                    ctx.font = `2em Arial`; // Set text size and font in em based on heightEm
                    ctx.fillText('Investering og afkast har udlignet sig', width - padding, zeroY - 10);
                }

                // Draw X-axis labels (with intervals of 3 months from 0 to 60)
                ctx.fillStyle = '#000';
                ctx.textAlign = 'center';
                for (let i = 0; i <= 60; i += 3) {
                    const x = padding + (i / 60) * chartWidth;
                    const y = height - padding + 30;
                    // Text size can be adjusted here with ctx.font in em to match the website's text size
                    ctx.font = `2em Arial`; // Set text size and font in em based on heightEm
                    ctx.fillText(`${i}`, x, y);
                }

                // Draw label under X-axis
                // Text size can be adjusted here with ctx.font in em to match the website's text size
                ctx.font = `2em Arial`; // Set text size and font in em based on heightEm
                ctx.fillText('Tid (Måneder)', width / 2, height - padding + 70);

                // Draw vertical lines for each year and label them
                ctx.fillStyle = '#888888';
                ctx.textAlign = 'center';
                ctx.font = `2em Arial`; // Set text size for year labels
                [12, 24, 36, 48, 60].forEach((month) => {
                    const x = padding + (month / 60) * chartWidth;
                    ctx.beginPath();
                    ctx.setLineDash([5, 5]); // Set line dash pattern for vertical line
                    ctx.moveTo(x, padding);
                    ctx.lineTo(x, height - padding);
                    ctx.strokeStyle = '#cccccc';
                    ctx.lineWidth = 2;
                    ctx.stroke();
                    ctx.setLineDash([]); // Reset line dash pattern
                    // Draw year label
                    ctx.fillText(`${month / 12} år`, x, padding - 10);
                });

                // Draw Y-axis labels in determined intervals with dotted lines, except for 0 and minValue
                ctx.textAlign = 'right';
                ctx.textBaseline = 'middle';
                for (let value = minValue; value <= maxValue; value += yInterval) {
                    const y = height - padding - ((value - minValue) / (maxValue - minValue)) * chartHeight;
                    // Text size can be adjusted here with ctx.font in em to match the website's text size
                    ctx.font = `2em Arial`; // Set text size and font in em based on heightEm
                    if (value !== 0 && value !== minValue) {
                        ctx.fillText(value.toFixed(0) + '%', padding- 10, y);

                        // Draw horizontal dotted line
                        ctx.beginPath();
                        ctx.setLineDash([5, 5]); // Set line dash pattern for dotted line
                        ctx.moveTo(padding, y);
                        ctx.lineTo(width - padding, y);
                        ctx.strokeStyle = '#cccccc';
                        ctx.lineWidth = 2;
                        ctx.stroke();
                        ctx.setLineDash([]); // Reset line dash pattern
                    }
                }

                // Draw Y-axis label for 0 if within range
                if (minValue < 0 && maxValue > 0) {
                    const zeroY = height - padding - ((0 - minValue) / (maxValue - minValue)) * chartHeight;
                    // Text size can be adjusted here with ctx.font in em to match the website's text size
                    ctx.font = `2em Arial`; // Set text size and font in em based on heightEm
                    ctx.fillText('0%', padding - 10, zeroY);
                }

                // Draw line chart
                ctx.beginPath();
                ctx.lineWidth = 5; // Gør grafens linje tykkere
                data.forEach((point, index) => {
                    const x = padding + (point.month / 60) * chartWidth;
                    const y = height - padding - ((point.ROI - minValue) / (maxValue - minValue)) * chartHeight;
                    if (index === 0) {
                        ctx.moveTo(x, y);
                    } else {
                        ctx.lineTo(x, y);
                    }
                });
                ctx.strokeStyle = '#8884d8';
                ctx.stroke();

                // Draw data points
                ctx.fillStyle = '#8884d8';
                data.forEach((point) => {
                    const x = padding + (point.month / 60) * chartWidth;
                    const y = height - padding - ((point.ROI - minValue) / (maxValue - minValue)) * chartHeight;
                    ctx.beginPath();
                    ctx.arc(x, y, 8, 0, 2 * Math.PI); // Increase the radius to make the points larger
                    ctx.fill();
                });
            }
        }
    };

    useEffect(() => {
        drawCanvas(canvasRef.current);
    }, [ROI3Mdr, ROI6Mdr, ROI12Mdr, ROI2Year, ROI3Year, ROI4Year, ROI5Year, drawCanvas]);

    // Effekt til at vise modalen, når showModal er true og tegne grafen i modalen med en timeout for at sikre, at modalen er synlig
    useEffect(() => {
        if (showModal) {
            setTimeout(() => drawCanvas(modalCanvasRef.current), 0);
        }
    }, [showModal, drawCanvas]);

    
    // Funktion til at håndtere klik på canvas og navigere til en anden URL med hash
    const handleOpenModal = () => {
        setShowModal(true);
        if (window.location.hash !== "#rapport-chart") {
            window.history.replaceState(null, "", "#rapport-chart");  // Tilføj #rapport-chart
        }
    };
    
    // Funktion til at håndtere klik på modalens luk-knap og navigere til en anden URL uden hash
    const handleCloseModal = () => {
        setShowModal(false);
        if (window.location.hash === "#rapport-chart") {
            window.history.replaceState(null, "", "#rapport");
        }
    };

    useEffect(() => {
        const handlePopState = (event: PopStateEvent) => {
    
            if (window.location.hash === "#rapport-chart") {
                // Luk kun dette modal og skift tilbage til #rapport
                setShowModal(false);
                window.history.replaceState(null, "", "#rapport");
                event.stopPropagation();
            }
        };
    
        window.addEventListener("popstate", handlePopState);
    
        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    return (
        <>
            <canvas
                ref={canvasRef}
                style={{ maxWidth: '100%', height: 'auto', cursor: 'pointer', transition: 'transform 0.2s ease-in-out' }}
                onClick={handleOpenModal} 
            />
            <Modal show={showModal} onHide={handleCloseModal} fullscreen>
                <Modal.Header className='bg-color20' closeButton>
                    <Modal.Title>
                        ROI Graf
                            <QuestionIconWithTooltip
                                text="Du kan zoome ind og ud, samt trække grafen rundt ved at bruge din mus eller touchpad."
                                className='ms-2'
                            />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TransformWrapper
                        maxScale={1}
                        minScale={0.3}
                        centerZoomedOut
                    >
                        <TransformComponent wrapperStyle={{ height: '100%', width: 'auto' }}>
                            <canvas
                                ref={modalCanvasRef}
                                height={1080}
                                width={1920}
                            />
                        </TransformComponent>
                    </TransformWrapper>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ROIChartV2;
